export default {
	data() {
		return {
			arBanners: [], 
			blLoading: true,
			obForm: {
				titulo: null
			},
			obFormPesquisa: {
				titulo: null
			}
		};
	},
	created() {
		this.listar();
	},
	methods: {
		listar() {
			this.$root.$api.get("banner/listar").then(response => {
				this.arBanners = response.retorno;

				this.blLoading = false;
			});
		},
		filtrar() {
			this.obFormPesquisa.titulo = this.obForm.titulo;
			// if (this.obForm.data) {
			// 	this.obFormPesquisa.data = this.obForm.data
			// 		.split("-")
			// 		.reverse()
			// 		.join("/");
			// } else {
			// 	this.obFormPesquisa.data = null
			// }
		},
	},
	computed: {
		listaBanners() {
			if (!this.arBanners) {
				return [];
			}

			return this.arBanners.filter(obVideo => {
				return (
					(!this.obFormPesquisa.titulo || obVideo.titulo.toLowerCase().indexOf(this.obFormPesquisa.titulo.toLowerCase()) >= 0) &&
					(!this.obFormPesquisa.data || obVideo.data == this.obFormPesquisa.data) &&
					(!this.obFormPesquisa.id_categoria || obVideo.id_categoria == this.obFormPesquisa.id_categoria)
				)
			});
		}
	}
}